<template>
    <span :class="{'cursor-pointer':displayDetails, 'hover-underline': displayDetails}"
          @click="displayUserDetails">
        <span v-if="user">
            <!-- Fontawesome icon for status -->
            <span v-if="roleInfos" v-b-tooltip.hover="roleInfos.name">
                <font-awesome-icon :icon="roleInfos.icon"/>
            </span>

            <!-- Actual name display -->
            <span v-if="user.pseudo" v-b-tooltip.hover="user.prenom + ' ' + user.nom">
                {{ user.pseudo }}
            </span>
            <span v-else>
                {{ user.prenom }} {{ user.nom }}
            </span>
        </span>
        <span v-else-if="defaultCluji">
            Cluji
        </span>
        <em v-else>
            Utilisateur introuvable
        </em>
    </span>
</template>

<script>
    import user     from '@/util/user';
    import {isUser} from '@/util/auth';

    const UserDetailsModal = () => import('@/components/modals/UserDetailsModal');

    export default {
        name: "UserName",
        props: {
            user: {
                type: Object,
                default: null
            },
            defaultCluji: {
                type: Boolean,
                default: false
            },
            canDisplayDetails: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            roleInfos() {
                return user.roleInfos(this.user);
            },
            displayDetails() {
                return this.user && this.canDisplayDetails && isUser();
            }
        },
        methods: {
            displayUserDetails() {
                if (this.displayDetails) {
                    this.$store.dispatch('modal/create', {
                        component: UserDetailsModal,
                        props: {utilisateurId: this.user.id}
                    })
                }
            }
        }
    }
</script>

<style scoped>
    .hover-underline:hover {
        text-decoration: underline;
    }
</style>